import { CSSProperties } from 'react';

import { Box, BoxProps, Flex, Image as Img, Text } from '@chakra-ui/react';
import { Card, Player } from 'models';
import unidecode from 'unidecode';

import { useAppSelector } from 'hooks/app';

interface FaceProps extends BoxProps {
  calcWidth: string;
  aspectRatio?: number;
  isOriginalImageUploaded: boolean;
  card?: Card | null;
  width?: string;
  detailed?: boolean;
  styles?: {
    image?: CSSProperties;
    info?: CSSProperties;
    firstName?: CSSProperties;
    lastName?: CSSProperties;
  };
  hasInjuryStatus?: boolean;
}

export const Face = (props: FaceProps) => {
  const { aspectRatio = 1 / 1.5, calcWidth, styles, isOriginalImageUploaded, card, detailed, hasInjuryStatus } = props;
  const leagueData = useAppSelector((state) => state.league.league);

  const getLeagueBySrId = (srId?: string | null) => {
    if (!srId) return;
    return leagueData?.results.find((team) => {
      return team.sportradarId === srId;
    });
  };

  return (
    <>
      <Box
        style={styles?.image}
        backgroundPosition="center center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundImage={`url(${isOriginalImageUploaded ? card?.headshotThumbnails?.original : card?.headshotThumbnails?.small})`}
        height={'70%'}
        borderRadius={`${16 * aspectRatio}px`}
        position="relative"
      >
        {hasInjuryStatus && (
          <Box
            position="absolute"
            bottom="0"
            left="0"
            w="100%"
            h={detailed ? `${23 * aspectRatio}px` : '12px'}
            color="rgba(36, 36, 36, 1)"
            fontSize={detailed ? `${11 * aspectRatio}px` : '8px'}
            lineHeight="1"
            fontWeight="bold"
            fontFamily="JetBrains Mono"
            display="flex"
            textTransform="uppercase"
            alignItems="center"
            justifyContent="center"
            borderRadius={`0 0 ${16 * aspectRatio}px ${16 * aspectRatio}px`}
            backgroundColor="rgba(255, 165, 0, 1)"
          >
            {detailed ? 'GAME-TIME DECISION' : 'GTD'}
          </Box>
        )}
      </Box>
      <Box textAlign="center" pt={`${8 * aspectRatio}px`} style={styles?.info}>
        {detailed ? (
          <>
            <Text
              variant="firstName"
              fontSize={`${16 * aspectRatio}px`}
              textTransform="uppercase"
              textColor="inherit"
              color="#9B9292"
              style={styles?.firstName}
            >
              {unidecode(card?.playerFirstName || '')}
            </Text>
            <Text
              variant="lastName"
              fontSize={`${64 * aspectRatio}px`}
              style={styles?.lastName}
              textTransform="uppercase"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {unidecode(card?.playerLastName || '')}
            </Text>
          </>
        ) : (
          <Text
            variant="firstName"
            fontSize={`${16 * aspectRatio}px`}
            textTransform="uppercase"
            textColor="inherit"
            color="#9B9292"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            style={styles?.firstName}
          >
            {unidecode(card?.playerLastName || '')}
          </Text>
        )}
      </Box>

      <Flex
        align="center"
        justify="center"
        pos="absolute"
        textTransform="uppercase"
        top={`${10 * aspectRatio}px`}
        left={`${10 * aspectRatio}px`}
        fontSize={`${14 * aspectRatio}px`}
        fontWeight="500"
        borderRadius="50%"
        width={`${28 * aspectRatio}px`}
        height={`${28 * aspectRatio}px`}
        fontFamily="JetBrains Mono"
        color="#ffffff"
        bg="#141414"
      >
        {card?.playerPrimaryPosition}
      </Flex>
      {(card?.homeTeamSrId || card?.awayTeamSrId) && (
        <Flex
          align="center"
          justify="center"
          pos="absolute"
          top={`${10 * aspectRatio}px`}
          right={`${10 * aspectRatio}px`}
          fontSize={`${14 * aspectRatio}px`}
          width={`${28 * aspectRatio}px`}
          height={`${28 * aspectRatio}px`}
          backgroundImage={
            card?.isHomeTeam
              ? `url(${getLeagueBySrId(card?.homeTeamSrId)?.logo})`
              : `url(${getLeagueBySrId(card?.awayTeamSrId)?.logo})`
          }
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        />
      )}
    </>
  );
};
