import { FC } from 'react';

import { Box, Fade, Flex, Grid, keyframes } from '@chakra-ui/react';

const shineAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

interface Props {
  height?: string;
  count?: number;
}

const UserInfoSkeleton: FC<Props> = () => {
  return (
    <Fade in={true} style={{ flex: 1 }}>
      <Flex flexDirection="column" flex="1" gap="0" mt="1px" alignItems="center" py="12px" mb="14px">
        <Box
          w="72px"
          h="72px"
          borderRadius="24px"
          transition="opacity 0.2s linear"
          backgroundImage="linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%)"
          backgroundSize="400px 100%"
          animation={`${shineAnimation} 1.5s infinite linear`}
          mb="16px"
        />
        <Box
          w="150px"
          h="24px"
          borderRadius="8px"
          transition="opacity 0.2s linear"
          backgroundImage="linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%)"
          backgroundSize="400px 100%"
          animation={`${shineAnimation} 1.5s infinite linear`}
          mb="6px"
        />
        <Box
          w="180px"
          h="18px"
          borderRadius="8px"
          transition="opacity 0.2s linear"
          backgroundImage="linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%)"
          backgroundSize="400px 100%"
          animation={`${shineAnimation} 1.5s infinite linear`}
          mb="8px"
        />
        {/* <Box
          w="140px"
          h="16px"
          borderRadius="8px"
          transition="opacity 0.2s linear"
          backgroundImage="linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%)"
          backgroundSize="400px 100%"
          animation={`${shineAnimation} 1.5s infinite linear`}
          mb="24px"
        /> */}
        <Flex flexDirection="row" gap="5px" alignItems="center" w="100%" px="12px">
          {Array.from({ length: 3 }).map((p, index) => (
            <Box
              key={`lbh-skeleton-${index}`}
              flex="1"
              h="70.4px"
              borderRadius="12px"
              transition="opacity 0.2s linear"
              backgroundImage="linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%)"
              backgroundSize="400px 100%"
              animation={`${shineAnimation} 1.5s infinite linear`}
            />
          ))}
        </Flex>
      </Flex>
    </Fade>
  );
};

export default UserInfoSkeleton;
