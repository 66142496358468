import { FC, useMemo } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { LeaderboardStatPeriod } from 'models';
import { getOrdinalSuffix } from 'utils/getOriginalSuffix';

import { ReactComponent as LeftArrowIcon } from 'icons/left-arr.svg';
import { ReactComponent as RightArrowIcon } from 'icons/right-arr.svg';

import LeaderboardHeaderSkeleton from './LeaderboardHeaderSkeleton';
import { Period } from './LeaderboardPeriodControls';

type Props = {
  stat?: LeaderboardStatPeriod;
  onNext(id: number): void;
  onPrev(id: number): void;
  disabled: boolean;
  type?: Omit<Period, 'daily'>;
};

const LeaderboardPeriodHeader: FC<Props> = ({ disabled, stat, onNext, onPrev, type }) => {
  const dateStr = useMemo(() => {
    if (!stat) return '';
    const startsAtNewYork = dayjs(stat.currentLeaderboard?.endDate).tz('America/Los_Angeles');
    const day = startsAtNewYork.format('D');
    const postfix = getOrdinalSuffix(parseInt(day));
    return startsAtNewYork.format('MMM ') + day + postfix;
  }, [stat]);

  function getWeekOfYear(date?: string) {
    const startOfYear = dayjs(date).startOf('year');
    const diffInDays = dayjs(date).diff(startOfYear, 'day');
    return Math.ceil((diffInDays + 1) / 7);
  }

  const weekFromStartOfTheYear = useMemo(() => {
    if (!stat) return '';
    const weekNumber = getWeekOfYear(stat.currentLeaderboard?.endDate);

    return weekNumber;
  }, [stat]);

  const month = useMemo(() => {
    if (!stat) return '';
    const startsAtNewYork = dayjs(stat.currentLeaderboard?.endDate).tz('America/Los_Angeles');
    return startsAtNewYork.format('MMM YYYY');
  }, [stat]);

  const formatNumber = (num: number) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  if (!dateStr) return <LeaderboardHeaderSkeleton />;

  return (
    <Flex
      bottom="0"
      justify="center"
      flexDir="column"
      align="center"
      fontSize="14px"
      lineHeight="16.8px"
      pos="sticky"
      padding="11px 18px 11px"
      bg="#242424"
      zIndex="10"
      borderTop="1px solid rgba(255,255,255,0.4)"
      mx="-12px"
    >
      <Box fontWeight="800">{type === 'weekly' ? `Week ${weekFromStartOfTheYear}` : month}</Box>
      <Box fontWeight="600" fontSize="14px" lineHeight={1.2} opacity={0.4}>
        Ends {dateStr}
      </Box>
      <Box
        pos="absolute"
        top="50%"
        left="18px"
        transform="translateY(-50%)"
        opacity={!disabled && stat?.previousLeaderboard ? '1' : '0.3'}
        onClick={!disabled && stat?.previousLeaderboard ? () => onPrev(stat.previousLeaderboard!.id) : undefined}
        w="30px"
        h="30px"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box as={LeftArrowIcon} />
      </Box>
      <Box
        pos="absolute"
        top="50%"
        right="18px"
        transform="translateY(-50%)"
        opacity={!disabled && stat?.nextLeaderboard ? '1' : '0.3'}
        onClick={!disabled && stat?.nextLeaderboard ? () => onNext(stat.nextLeaderboard!.id) : undefined}
        w="30px"
        h="30px"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Box as={RightArrowIcon} />
      </Box>
    </Flex>
  );
};

export default LeaderboardPeriodHeader;
