// import { useEffect, useState } from 'react';
// import { useSearchParams } from 'react-router-dom';
// import { Box, Flex, Text } from '@chakra-ui/react';
// const LeaderboardFriendsFilter = () => {
//   const [searchParams, setSearchParams] = useSearchParams();
//   const filterButtons = [
//     {
//       name: 'all',
//       value: false,
//     },
//     {
//       name: 'friends',
//       value: true,
//     },
//   ];
//   return (
//     <Flex w="100%" flexDirection="row" justifyContent="space-between" pl="54px" gap="10px">
//       <Flex flex="1" justifyContent="flex-start">
//         <Text textAlign="left" variant="pageTitle">
//           leaderboard
//         </Text>
//       </Flex>
//       <Flex flex="1" justifyContent="flex-end" alignItems="center" gap="8px">
//         {filterButtons.map((button) => (
//           <Flex
//             key={button.name}
//             background={searchParams.get('friends') === `${button.value}` ? '#fff' : 'rgba(255,255,255,0.06)'}
//             p="4px 10px"
//             borderRadius="10px"
//             transition="all 0.2s ease"
//             onClick={() => {
//               setSearchParams((sp) => {
//                 const newParams = new URLSearchParams(sp);
//                 newParams.set('friends', `${button.value}`);
//                 return newParams;
//               });
//             }}
//           >
//             <Text
//               fontSize="16px"
//               fontWeight="700"
//               lineHeight="1"
//               transition="all 0.2s ease"
//               color={
//                 searchParams.get('friends') === `${button.value}` ? 'rgba(36, 36, 36, 1)' : 'rgba(255,255,255,0.8)'
//               }
//             >
//               {button.name}
//             </Text>
//           </Flex>
//         ))}
//       </Flex>
//     </Flex>
//   );
// };
// export default LeaderboardFriendsFilter;
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Flex, Text } from '@chakra-ui/react';

const LeaderboardFriendsFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.has('friends')) {
      setSearchParams((sp) => {
        const newParams = new URLSearchParams(sp);
        newParams.set('friends', `false`);
        return newParams;
      });
    }
  }, [searchParams, setSearchParams]);

  const filterButtons = [
    {
      name: 'all',
      value: false,
    },
    {
      name: 'friends',
      value: true,
    },
  ];

  return (
    <Flex w="100%" flexDirection="row" justifyContent="space-between" pl="54px" gap="10px">
      <Flex flex="1" justifyContent="flex-start">
        <Text textAlign="left" variant="pageTitle">
          leaderboard
        </Text>
      </Flex>
      <Flex flex="1" justifyContent="flex-end" alignItems="center" gap="8px">
        {filterButtons.map((button) => (
          <Flex
            key={button.name}
            background={searchParams.get('friends') === `${button.value}` ? '#fff' : 'rgba(255,255,255,0.06)'}
            p="4px 10px"
            borderRadius="10px"
            transition="all 0.2s ease"
            cursor="pointer"
            _active={{ backgroundColor: 'rgba(255,255,255,0.1)' }}
            onClick={() => {
              setSearchParams((sp) => {
                const newParams = new URLSearchParams(sp);
                newParams.set('friends', `${button.value}`);
                return newParams;
              });
            }}
          >
            <Text
              fontSize="16px"
              fontWeight="700"
              lineHeight="1"
              transition="all 0.2s ease"
              color={
                searchParams.get('friends') === `${button.value}` ? 'rgba(36, 36, 36, 1)' : 'rgba(255,255,255,0.8)'
              }
            >
              {button.name}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default LeaderboardFriendsFilter;
