import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Fade, Flex, Grid, Image, SlideFade } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Booster, Contest } from 'models';
import routes from 'routes';
import { formatDuration } from 'utils/formatDuration';
import { preloadImage } from 'utils/preloadImage';

import { useLazyGetParticipantBoostersQuery } from 'api/contest';

import BoosterCard from 'components/booster/BoosterCard';

import { useAppSelector } from 'hooks/app';

import BoosterCardSkeleton from './BoosterCardSkeleton';

type Props = {
  boosters: Booster[] | undefined;
  title: string;
  subTitle: string;
  onSelect?: (booster: Booster) => void;
  isLoading?: boolean;
};
const Boosters: FC<Props> = ({ boosters, onSelect, title, subTitle, isLoading }) => {
  const navigate = useNavigate();
  const { nextContest } = useAppSelector((store) => store.contest);
  const [isOpen, setIsOpen] = useState<boolean[]>([]);
  const [fetchBoosters, { data: uploadedBoosters, isFetching }] = useLazyGetParticipantBoostersQuery();

  useEffect(() => {
    if (boosters) {
      const openBoosters = async () => {
        for (let i = 0; i < boosters.length; i++) {
          await new Promise((resolve) => setTimeout(resolve, 50));
          setIsOpen((prev) => {
            const newIsOpen = [...prev];
            newIsOpen[i] = true;
            return newIsOpen;
          });
        }
      };
      openBoosters();
    }

    if (!boosters || boosters.length === 0) {
      fetchBoosters();
    }
  }, [boosters]);

  return (
    <Box>
      <Fade in={true}>
        <Box pos="relative" textAlign="center" p="14px 0 4px">
          <Box fontSize="18px" fontWeight="800" lineHeight="21.6px">
            {title}
          </Box>
          <Box fontSize="14px" fontWeight="500" lineHeight="15.4px" textAlign="center" mt="4px">
            {subTitle}
          </Box>
          <Box
            pos="absolute"
            right="16px"
            top="50%"
            transform="translateY(-50%)"
            fontSize="14px"
            fontWeight="500"
            as={Link}
            to={onSelect ? routes.squad : routes.home}
          >
            close
          </Box>
        </Box>
      </Fade>

      {(isLoading || !boosters || isFetching) && (
        <Grid mt="20px" templateColumns="repeat(auto-fill, 170px)" gap="11px" justifyContent="center">
          {Array.from({ length: 6 }).map((_, index) => (
            <BoosterCardSkeleton key={index} />
          ))}
        </Grid>
      )}
      {boosters && boosters.length > 0 && (
        <Grid mt="20px" templateColumns="repeat(auto-fill, 170px)" gap="11px" justifyContent="center" pb="10px">
          {boosters.map((booster, index) => (
            <SlideFade key={booster.id} in={isOpen[index]} offsetY="10px">
              <BoosterCard
                booster={booster}
                cursor="pointer"
                onClick={() => {
                  onSelect?.(booster);
                  onSelect && navigate(routes.squad);
                }}
              />
            </SlideFade>
          ))}
          {nextContest && <BoosterPack nextContest={nextContest} />}
        </Grid>
      )}

      {!isFetching && boosters && boosters.length === 0 && (
        <Box
          maxW="268px"
          w="100%"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          fontSize="14px"
          fontWeight="500"
          textAlign="center"
          lineHeight="16.8px"
        >
          No boosters available. Come up for daily spins, shop, whatever.
        </Box>
      )}
    </Box>
  );
};

export default Boosters;

const notFirstSpinImage =
  'https://clutchpoints-store-media.s3.us-east-1.amazonaws.com/SBC+media/booster-icon-placeholders/sbc-daily-pack.webp';

preloadImage(notFirstSpinImage);

const BoosterPack = ({ nextContest }: { nextContest: Contest | null }) => {
  const now = dayjs().tz('America/Los_Angeles');
  const tommorow = dayjs().tz('America/Los_Angeles').add(1, 'day').startOf('day');
  const locksDiff = tommorow.diff(now);
  const durationUntilLocks = dayjs.duration(locksDiff);
  const time = formatDuration(durationUntilLocks);
  const buttonText = parseInt(time) < 0 ? 'soon' : `${time}`;

  return (
    <>
      <Flex position="relative" alignItems="center" justifyContent="center">
        <Box
          position="absolute"
          top="5px"
          right="5px"
          transform="translate(0%, 0) rotate(6.2deg)"
          backgroundColor="#F83C3C"
          borderRadius="20px"
          color="white"
          fontSize="14px"
          w="fit-content"
          whiteSpace="nowrap"
          lineHeight="1.2"
          p="2px 4px"
          fontWeight="800"
          textShadow={`0 -1px 0px rgba(0, 0, 0, 0.3)`}
        >
          {buttonText}
        </Box>
        <Image
          width="calc(100% - 5px)"
          height="calc(100% - 5px)"
          objectFit="contain"
          src={notFirstSpinImage}
          alt="Booster Pack"
        />
      </Flex>
    </>
  );
};
