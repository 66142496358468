import { type User } from 'models';

import { api } from './api';

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: '/user/profile/',
      }),
    }),
    getUserById: builder.query<User, { userId: string }>({
      query: ({ userId }) => ({
        url: `/user/profile/?user_id=${userId}`,
      }),
      providesTags: (result, error, { userId }) => (result ? [{ type: 'User', id: userId }] : []),
    }),
    signIn: builder.mutation<User, { email: string; redirectUri: string }>({
      query: ({ email, redirectUri }) => ({
        url: `user/auth/email/`,
        method: 'POST',
        params: { email, redirectUri },
        headers: {
          accept: 'application/json',
        },
      }),
    }),
    signInApple: builder.mutation<User, { id: string; identityToken: string }>({
      query: ({ id, identityToken }) => ({
        url: `auth/apple/`,
        method: 'POST',
        body: { id, identityToken },
        headers: {
          accept: 'application/json',
        },
      }),
    }),
    signInFB: builder.mutation<User, { accessToken: string; facebookId: string }>({
      query: ({ accessToken, facebookId }) => ({
        url: `auth/fb/`,
        method: 'POST',
        body: { accessToken, facebookId },
        headers: {
          accept: 'application/json',
        },
      }),
      extraOptions: {
        disableCamelcase: false,
      },
    }),
    signInGoogle: builder.mutation<{ token: string }, { credential: string }>({
      query: ({ credential }) => ({
        url: `user/auth/google/`,
        method: 'POST',
        body: { credential },
        headers: {
          accept: 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUserByIdQuery,
  useSignInMutation,
  useSignInFBMutation,
  useSignInAppleMutation,
  useSignInGoogleMutation,
} = authApi;
