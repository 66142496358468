import { FC } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Box, Flex, Grid, Img } from '@chakra-ui/react';
import { UserInPeriodLeaderboard } from 'models';
import routes from 'routes';
import { formatNumberWithCommas } from 'utils/formatNumberWithCommas';

import ParticipantPosition from './ParticipantPosition';

type Props = {
  participant: UserInPeriodLeaderboard;
  position: number;
};

const LeaderboardPeriodRow: FC<Props> = ({ participant, position }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { period } = useParams();

  const contestId = searchParams.get('contestId');
  const friends = searchParams.get('friends') === 'true';

  const user = participant.user;

  return (
    <>
      <Box p="8px 0" borderBottom="0.5px solid #FFFFFF4D" cursor="pointer" transition="all 0.2s ease-in-out">
        <Grid
          gap="10px"
          alignItems="flex-start"
          justifyContent="space-between"
          gridTemplateColumns="30px 1fr"
          color={position === 0 ? '#FFD700' : 'white'}
        >
          <Flex align="end" flexBasis="100%" maxW="28px">
            <ParticipantPosition position={position + 1} />
          </Flex>
          <Flex
            gap="12px"
            align="center"
            fontSize="14px"
            fontWeight="800"
            lineHeight="16.94px"
            flexShrink="0"
            flexGrow="0"
            flexBasis="286px"
          >
            <Flex
              flex="1"
              align="center"
              gap="8px"
              w="100%"
              justifyContent="flex-start"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${routes.profile}/${user.id}`, {
                  state: {
                    from: `${routes.leaderboard}/${period}/?${contestId ? 'contestId=' + contestId : ''}&friends=${!!friends}`,
                  },
                });
              }}
              borderRadius="12px"
              cursor="pointer"
              transition="all 0.2s ease-in-out"
              _active={{ backgroundColor: 'rgba(255,255,255,0.05)' }}
            >
              <Flex
                w="36px"
                h="36px"
                borderRadius="12px"
                background="#FFFFFF1A"
                fontSize="16px"
                fontWeight="800"
                justify="center"
                align="center"
                color="#FFFFFFCC"
                flexShrink="0"
                textTransform="uppercase"
                backgroundPosition="center center"
                backgroundSize="cover"
                backgroundImage={user.avatarUrl ? `url(${user.avatarUrl})` : undefined}
              >
                {user.avatarUrl ? null : user.username[0]}
              </Flex>
              <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                {user.username}
              </Box>
            </Flex>
            <Flex align="center" gap="2px">
              {formatNumberWithCommas(participant.points ?? 0)}
              <Img w="20px" h="20px" src="/coin.png" />
            </Flex>
          </Flex>
        </Grid>
      </Box>
    </>
  );
};

export default LeaderboardPeriodRow;
