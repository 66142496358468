import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, useToast } from '@chakra-ui/react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import routes from 'routes';

import { useInviteUserMutation } from 'api/contest';

interface Props {
  code?: string;
  onSuccessfulInvite?: () => void;
  onFailedInvite?: () => void;
}

const useInviteUser = ({ code, onSuccessfulInvite, onFailedInvite }: Props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [inviteUser, { isLoading, isSuccess, isError, error, data }] = useInviteUserMutation();

  useEffect(() => {
    if (code) {
      inviteUser({ code: code });
    }
  }, [code]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        render: ({ onClose }) => {
          const successMessage = (data as any).message || 'Friend successfully added';
          return (
            <Flex
              alignItems="center"
              justifyContent="center"
              p="5px 18px !important"
              backgroundColor="#111111"
              borderRadius="10px"
              gap="10px"
              w="min-content"
              whiteSpace="nowrap"
              margin="0 auto 10px"
              onClick={onClose}
            >
              <Box>{successMessage}</Box>
            </Flex>
          );
        },
      });
      localStorage.removeItem('friend_invite_code');
      onSuccessfulInvite?.();
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        render: ({ onClose }) => {
          const errorMessage = (error as any).data.message || 'Sign in to accept the invite';
          return (
            <Flex
              alignItems="center"
              justifyContent="center"
              p="5px 18px !important"
              backgroundColor="#111111"
              borderRadius="10px"
              gap="10px"
              w="min-content"
              whiteSpace="nowrap"
              margin="0 auto 10px"
              onClick={onClose}
            >
              <Box>{errorMessage}</Box>
            </Flex>
          );
        },
      });
      if (code) {
        localStorage.setItem('friend_invite_code', code);
      }
      if ((error as any)?.data.message === 'You cannot invite yourself') {
        localStorage.removeItem('friend_invite_code');
        navigate(routes.home);
      }
      onFailedInvite?.();
    }
  }, [isError, error]);

  return { inviteUser, isLoading, isSuccess, isError };
};

export default useInviteUser;
