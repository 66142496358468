import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Flex, Spinner } from '@chakra-ui/react';
import { ContestParticipant, User } from 'models';

import { useGetUserByIdQuery } from 'api/auth';
import { useLazyGetParticipantHistoryQuery } from 'api/contest';

import { useAppSelector } from 'hooks/app';

import ContestHistoryList from './ContestHistoryList';

const ContestHistory: React.FC = () => {
  const { userId } = useParams();
  const userFromStore = useAppSelector((store) => store.auth.user);
  const { data: currentUser, isSuccess, isLoading } = useGetUserByIdQuery({ userId: userId! }, { skip: !userId });
  const user = userId ? currentUser : userFromStore;

  const loadRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);
  const [participations, setParticipations] = useState<ContestParticipant[]>([]);
  const [trigger, { data, isFetching, isLoading: isUserHistoryLoading }] = useLazyGetParticipantHistoryQuery();

  useEffect(() => {
    if (user && user?.id && !isFetching) {
      trigger({ offset: 0, limit: page * 5, userId: user.id });
    }
  }, [user, page]);

  useEffect(() => {
    if (data) {
      setParticipations(data.results);
    }
  }, [data]);

  useEffect(() => {
    let observer: IntersectionObserver;
    if (loadRef.current) {
      observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setPage((prevPage) => prevPage + 1);
          }
        },
        { threshold: 1.0 }
      );

      observer.observe(loadRef.current);
    }

    return () => {
      if (loadRef.current) {
        observer?.unobserve(loadRef.current);
      }
    };
  }, [isFetching, loadRef]);

  return (
    <Flex flex="1" flexDirection="column">
      <ContestHistoryList isLoading={isUserHistoryLoading} participations={participations} />
      {data?.next && (
        <Flex w="100%" justifyContent="center" p="60px 0">
          {!isFetching && <Box ref={loadRef}></Box>}
          {isFetching && <Spinner size="sm" color="rgba(255,255,255,0.4)" />}
        </Flex>
      )}
    </Flex>
  );
};

export default ContestHistory;
