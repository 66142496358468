import { Box, Fade, Flex } from '@chakra-ui/react';
import { Contest, ContestParticipant } from 'models';

import LeaderboardSkeleton from 'components/leaderboard/LeaderboardSkeleton';

import ContestHistoryItem from './ContestHistoryItem';
import EmptyContestHistory from './EmptyContestHistory';

interface ContestHistoryListProps {
  participations: ContestParticipant[];
  isLoading?: boolean;
}

const ContestHistoryList: React.FC<ContestHistoryListProps> = ({ participations, isLoading }) => {
  return (
    <Flex flex="1" flexDirection="column">
      {isLoading && (
        <Box p="0px 12px" alignContent="center" flex="1">
          <LeaderboardSkeleton />
        </Box>
      )}
      {!isLoading && (
        <Box style={{ transitionDelay: '0.1s', flex: 1, display: 'flex' }}>
          {participations.length > 0 && (
            <Box p="0px 12px" alignContent="start" flex="1">
              {participations.map((participation, index) => (
                <ContestHistoryItem key={`${participation.id}-${index}`} participation={participation} />
              ))}
            </Box>
          )}
          {participations.length === 0 && !isLoading && (
            <Box p="0px 12px" alignContent="center" flex="1">
              <EmptyContestHistory />
            </Box>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default ContestHistoryList;
