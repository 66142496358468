import { useEffect } from 'react';

import { League } from 'models';

import { useGetLeagueQuery } from 'api/api';

import { setLeague } from 'store/slices/league';

import { useAppDispatch } from './app';

const useLeague = (leagueId: League) => {
  const dispatch = useAppDispatch();
  const { data, error, isLoading } = useGetLeagueQuery({ league: leagueId });

  useEffect(() => {
    if (data) {
      dispatch(setLeague({ league: data }));
    }
  }, [data]);

  return { data, error, isLoading };
};

export default useLeague;
