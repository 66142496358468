import { Box, Flex, Img, Text } from '@chakra-ui/react';
import { User } from 'models';

import { ReactComponent as FlameIcon } from 'icons/flame.svg';

interface Props {
  user: User;
}
const Stats: React.FC<Props> = ({ user }) => {
  return (
    <Flex flexDir="row" gap="5px">
      <Flex
        flexDirection="column"
        py="16px"
        backgroundColor="rgba(51, 51, 51, 1)"
        borderRadius="16px"
        alignItems="center"
        flex="1"
      >
        <Text fontSize="18px" lineHeight="1.2">
          {user.sbcContestCount}
        </Text>
        <Text fontSize="14px" lineHeight="1.2" color="rgba(255,255,255,0.6)" fontWeight="600">
          {(user.sbcContestCount ?? 0) > 1 ? 'squads' : 'squad'}
        </Text>
      </Flex>
      <Flex
        flexDirection="column"
        py="16px"
        backgroundColor="rgba(51, 51, 51, 1)"
        borderRadius="16px"
        alignItems="center"
        flex="1"
      >
        <Text fontSize="18px" lineHeight="1.2" display="flex" alignItems="center" gap="2px">
          {user.sbcAllStreakCount}
          <Box w="18px" h="18px" as={FlameIcon} />
        </Text>
        <Text fontSize="14px" lineHeight="1.2" color="rgba(255,255,255,0.6)" fontWeight="600">
          streak
        </Text>
      </Flex>
      <Flex
        flexDirection="column"
        py="16px"
        backgroundColor="rgba(51, 51, 51, 1)"
        borderRadius="16px"
        alignItems="center"
        flex="1"
      >
        <Text fontSize="18px" lineHeight="1.2" display="flex" alignItems="center" gap="2px">
          {user.sbcMaxScore}
          {/* <Box w="18px" h="18px" as={FlameIcon} /> */}
          <Img w="20px" src="/coin.png" />
        </Text>
        <Text fontSize="14px" lineHeight="1.2" color="rgba(255,255,255,0.6)" fontWeight="600">
          top score
        </Text>
      </Flex>
    </Flex>
  );
};

export default Stats;
