import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Flex, Portal } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ContestParticipant, ContestStatus, LeaderboardStat } from 'models';
import { getOrdinalSuffix } from 'utils/getOriginalSuffix';

import Squad from 'components/builder/Squad/Squad';

import { ReactComponent as RightArrowIcon } from 'icons/right-arr.svg';

type Props = {
  stat: LeaderboardStat;
  contestStatus?: ContestStatus;
  noSquad?: boolean;
  myPlaceAmongFriends?: number;
};

const MySquad: FC<Props> = ({ stat, contestStatus, noSquad, myPlaceAmongFriends }) => {
  const [searchParams] = useSearchParams();
  if (stat.me?.status !== 'completed') {
    return null;
  }

  const totalPoints = stat.me.totalPoints === null || stat.me.totalPoints === undefined ? 'TBA' : stat.me.totalPoints;

  const [detailShowed, setDetailShowed] = useState(false);

  const isContestLockedOrActive = contestStatus === 'locked' || contestStatus === 'active';
  const isParticipantCompleted = stat.me.status === 'completed';
  const place = searchParams.get('friends') === 'true' ? myPlaceAmongFriends : stat.me.place;
  const placeString = place ? place.toString() : '0';
  const ordinalSuffix = getOrdinalSuffix(parseInt(placeString));
  const pointsText = `you're ${place}${ordinalSuffix} with ${totalPoints}`;

  const text = isContestLockedOrActive && isParticipantCompleted ? 'my squad' : pointsText;

  useEffect(() => {
    if (detailShowed) {
      document.body.classList.add('locked');
    } else {
      document.body.classList.remove('locked');
    }
    return () => {
      document.body.classList.remove('locked');
    };
  }, [detailShowed]);

  if (detailShowed) {
    const date = dayjs(stat.me.updated).tz('America/Los_Angeles');
    const dateStr = date.format('MMM D');

    return (
      <Portal>
        <Squad
          participation={stat.me}
          cards={stat.me.cards.cardsWithStatus}
          pos="fixed"
          top="0"
          bottom="0"
          left="0"
          right="0"
          bg="var(--chakra-colors-bg)"
          onClose={() => setDetailShowed(false)}
          title="Start Bench Cut"
          description={`${dateStr} · ${stat.me.user.username}`}
          overflow="auto"
          detailed
          zIndex="100"
          contestStatus={contestStatus}
          className="portal"
        />
      </Portal>
    );
  }

  return (
    <Box
      w="min-content"
      mx="auto"
      bg="#F83C3C"
      p="5px 12px"
      borderRadius="20px"
      mt="16px"
      mb="8px"
      boxShadow="0 0 50px 0 rgba(248, 60, 60, 0.25)"
      cursor="pointer"
      transition="all 0.2s ease-in-out"
      _active={{ boxShadow: '0 0 50px 0 rgba(248, 60, 60, 0.5)' }}
    >
      <Flex align="center" justify="space-between" gap="8px">
        <Flex align="center" gap="8px" maxW="218px" marginRight="auto">
          <Flex
            w="28px"
            h="28px"
            borderRadius="8px"
            background="#FFFFFF1A"
            fontSize="16px"
            fontWeight="800"
            justify="center"
            align="center"
            color="#FFFFFFCC"
            flexShrink="0"
            textTransform="uppercase"
            backgroundPosition="center center"
            backgroundSize="cover"
            backgroundImage={stat.me.user.avatarUrl ? `url(${stat.me.user.avatarUrl})` : undefined}
          >
            {stat.me.user.avatarUrl ? null : stat.me.user.username[0]}
          </Flex>
        </Flex>
        <Flex
          fontSize="14px"
          fontWeight="700"
          lineHeight="16.8px"
          align="center"
          gap="7px"
          onClick={() => (noSquad ? {} : setDetailShowed(true))}
          whiteSpace="nowrap"
        >
          {text}
          {!noSquad && <Box as={RightArrowIcon} w="5px" h="8px" />}
        </Flex>
      </Flex>
      {/* <Flex justify="center" align="center" mt="16px">
        {startCards.map((card) => (
          <CardPlaceholder key={card.id} ml="-2px" mr="-2px" boxShadow="4px 0px 20px 0px #00000080" card={card} />
        ))}
        {benchCard && (
          <CardPlaceholder
            ml="-2px"
            mr="-2px"
            boxShadow="4px 0px 20px 0px #00000080"
            bgColor="#4F4F4F"
            color="#D2C9C9"
            card={benchCard}
          />
        )}
        {booster ? (
          <Box
            pos="relative"
            ml="-2px"
            mr="-2px"
            w="60px"
            h="60px"
            backgroundColor={booster.backgroundColor ?? 'transparent'}
            borderRadius="14px"
            backgroundImage={`url(${booster.image})`}
            backgroundSize="cover"
            backgroundPosition="center center"
            boxShadow="4px 0px 20px 0px #00000080"
          >
            {booster.icon && (
              <Img src={booster.icon} pos="absolute" top="50%" left="50%" w="60px" transform="translate(-50%, -50%)" />
            )}
          </Box>
        ) : (
          <Box
            pos="relative"
            ml="-2px"
            mr="-2px"
            w="60px"
            h="60px"
            backgroundColor="#111111"
            borderRadius="14px"
            boxShadow="4px 0px 20px 0px #00000080"
          >
            <Box as={NoneIcon} pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" />
          </Box>
        )}
      </Flex> */}
      {/* <Flex mt="16px" justify="center" gap="24px">
        <Box textAlign="center" w="80px">
          <Box fontSize="20px" fontWeight="800" lineHeight="24px">
            {totalPoints}
          </Box>
          <Box fontSize="12px" fontWeight="600" lineHeight="14.4px" opacity="0.8">
            earnings
          </Box>
        </Box>
        <Box textAlign="center" w="80px">
          <Box fontSize="20px" fontWeight="800" lineHeight="24px">
            {participant.place ?? 'TBA'}
          </Box>
          <Box fontSize="12px" fontWeight="600" lineHeight="14.4px" opacity="0.8">
            place
          </Box>
        </Box>
      </Flex> */}
    </Box>
  );
};

export default MySquad;
