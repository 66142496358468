import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Fade, Flex, Img, useToast } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { User } from 'models';

import { useCreateFakeUserMutation } from 'api/contest';

import { useAppSelector } from 'hooks/app';

interface AddFakeUserProps {}

const AddFakeUser = ({}: AddFakeUserProps) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const { contest } = useAppSelector((store) => store.contest);
  const user = useAppSelector((store) => store.auth.user);
  const toast = useToast();
  const [createFakeUser, { data, isSuccess, isLoading }] = useCreateFakeUserMutation();
  const navigate = useNavigate();

  const addUserHnadler = () => {
    if (isLoading || isSuccess) return;
    createFakeUser({ id: contest?.id ?? 0 });
  };

  useEffect(() => {
    if (data && isSuccess) {
      localStorage.setItem('is_fake_user', 'true');
      localStorage.removeItem('sbc_tutorial_1');
      localStorage.removeItem('sbc_tutorial_2');
      Cookies.set('sbc_access_token', data.token);

      toast({
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        render: ({ onClose }) => {
          return (
            <Flex
              alignItems="center"
              justifyContent="center"
              p="5px 18px !important"
              backgroundColor="#111111"
              borderRadius="10px"
              gap="10px"
              w="min-content"
              whiteSpace="nowrap"
              margin="0 auto 10px"
              onClick={onClose}
            >
              <Box>User Created</Box>
            </Flex>
          );
        },
      });

      navigate(0);
    }
  }, [data]);

  return (
    <>
      <Flex
        opacity={!!user ? 1 : 0}
        ref={buttonRef}
        position="relative"
        alignItems="center"
        gap="6px"
        margin="0 0 20px"
        onClick={addUserHnadler}
        cursor="pointer"
        transition="all 0.2s ease-in-out"
        _active={{ opacity: 0.5 }}
      >
        <Img
          src={`data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.75 0C4.36522 0 0 4.36522 0 9.75C0 15.1348 4.36522 19.5 9.75 19.5C15.1348 19.5 19.5 15.1348 19.5 9.75C19.5 4.36522 15.1348 0 9.75 0ZM10.5 6.75C10.5 6.33579 10.1642 6 9.75 6C9.33579 6 9 6.33579 9 6.75V9H6.75C6.33579 9 6 9.33579 6 9.75C6 10.1642 6.33579 10.5 6.75 10.5H9V12.75C9 13.1642 9.33579 13.5 9.75 13.5C10.1642 13.5 10.5 13.1642 10.5 12.75V10.5H12.75C13.1642 10.5 13.5 10.1642 13.5 9.75C13.5 9.33579 13.1642 9 12.75 9H10.5V6.75Z' fill='white'/%3E%3C/svg%3E%0A`}
          alt="feedback"
          w="20px"
          h="20px"
          objectFit="contain"
        />
        <Box fontSize="18px" lineHeight="25px" fontWeight="800" textShadow="0 -1px 0 0 rgba(0,0,0,0.5)" pb="3px">
          new player
        </Box>
      </Flex>
    </>
  );
};

export default AddFakeUser;
