import { FC } from 'react';

import { Box } from '@chakra-ui/react';
import { Card } from 'models';
import unidecode from 'unidecode';

const CutCard: FC<{ card: Card }> = ({ card }) => {
  const headshot =
    card?.headshotThumbnails?.small ?? card?.headshotThumbnails?.medium ?? card?.headshotThumbnails?.original ?? '';
  return (
    <Box width="72px">
      {/* <Box
        w="60px"
        h="60px"
        mx="auto"
        backgroundSize="cover"
        backgroundPosition="center center"
        backgroundImage={`url(${headshot})`}
        borderRadius="16px"
        bgColor="#B83B3B"
      /> */}

      <Box
        w="60px"
        h="60px"
        mx="auto"
        bgColor="#B83B3B"
        borderRadius="16px"
        position="relative"
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '16px',
          backgroundImage: `url(${headshot})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          filter: 'grayscale(100%)',
        }}
      />

      <Box
        fontFamily="Jetbrains Mono"
        fontWeight="400"
        lineHeight="10px"
        fontSize="10px"
        textAlign="center"
        mt="8px"
        textTransform="uppercase"
      >
        {unidecode(card?.playerLastName || '')}
      </Box>
    </Box>
  );
};

export default CutCard;
