import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Flex, FormControl, Grid, Input, Link, SlideFade, Text, useToast } from '@chakra-ui/react';
import { use } from 'chai';
import Cookies from 'js-cookie';
import { RegExpMatcher, englishDataset, englishRecommendedTransformers } from 'obscenity';

import { useUpdateFakeUserMutation, useUpdateUserEmailMutation, useUpdateUserNameMutation } from 'api/user';

import { useAppSelector } from 'hooks/app';

interface FormData {
  username: string;
  email: string;
}

const ChangeUserInfoPage = () => {
  const user = useAppSelector((store) => store.auth.user);
  const {
    handleSubmit,
    register,
    getFieldState,
    watch,
    formState: { errors, isValid, isDirty, touchedFields },
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: { username: user?.username ?? '', email: user?.email ?? '' },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [updateUser, updateUserReq] = useUpdateFakeUserMutation();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const {
    isError: isUpdateError,
    error: updateError,
    isLoading: isUserUpdating,
    isSuccess,
    originalArgs,
    data,
  } = updateUserReq;
  let backLink = '/';

  if (location.state && location.state.from) {
    backLink = location.state.from;
  }

  const filter = new RegExpMatcher({
    ...englishDataset.build(),
    ...englishRecommendedTransformers,
  });

  const onSubmit = (formData: FormData) => {
    ReactGA.event({
      category: 'Profile',
      action: 'submit',
      label: 'Username Changed',
    });
    updateUser({ ...user, username: formData.username, email: formData.email });
  };

  useEffect(() => {
    const username = watch('username');
    const state = getFieldState('username');
    if (!!errors.username?.message) {
      setErrorMessage(errors.username?.message);
    } else if (isUpdateError && originalArgs?.username === username) {
      setErrorMessage('username is taken already');
    } else if (filter.hasMatch(username)) {
      setErrorMessage('ahh we don’t do that here');
    } else {
      setErrorMessage(null);
    }
  }, [errors, watch('username'), isUpdateError]);

  useEffect(() => {
    if (isUserUpdating) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isUserUpdating]);

  useEffect(() => {
    if (isSuccess && !isUpdateError) {
      setTimeout(() => {
        navigate(backLink, {
          state: {
            userNameUpdateStatus: { message: 'Name Changed', isSuccess: !isUpdateError },
          },
        });
      }, 1000);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess && data) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        render: ({ onClose }) => {
          return (
            <Flex
              alignItems="center"
              justifyContent="center"
              p="5px 18px !important"
              backgroundColor="#111111"
              borderRadius="10px"
              gap="10px"
              w="min-content"
              whiteSpace="nowrap"
              margin="0 auto 10px"
              onClick={onClose}
            >
              <Box>Name Changed</Box>
            </Flex>
          );
        },
      });

      const isFake = localStorage.getItem('is_fake_user') === 'true';

      if (isFake) {
        Cookies.remove('sbc_access_token');
        localStorage.removeItem('is_fake_user');
      }

      setTimeout(() => {
        window.history.replaceState({}, document.title);
      }, 4000);
    }
  }, [isSuccess, data]);
  return (
    <Flex
      flexDirection="column"
      pos="absolute"
      w="100%"
      maxW="500px"
      h="100%"
      backgroundColor="#242424"
      zIndex="999"
      top="0px"
      left="50%"
      p="10px 12px"
      transform="translateX(-50%)"
    >
      <Grid gridTemplateColumns="48px 1fr 48px" gap="8px" alignItems="center" justifyContent="center">
        <Box />
        <Box fontSize="16px" lineHeight="1.2" fontWeight="800" textAlign="center">
          {/* edit name */}
        </Box>
        <Box
          as={Link}
          href={backLink}
          display="flex"
          alignItems="center"
          aspectRatio="1/1"
          fontSize="14px"
          lineHeight="1.2"
          fontWeight="500"
          textAlign="right"
          cursor="pointer"
          textDecoration="none !important"
          _active={{
            opacity: '0.5',
            transition: 'opacity 0.2s',
          }}
        >
          close
        </Box>
      </Grid>

      <Flex
        as="form"
        flexDirection="column"
        onSubmit={handleSubmit(onSubmit)}
        gap="24px"
        flex="1"
        justifyContent="flex-start"
        p="0 12px"
        pos="relative"
        mt="18px"
      >
        <Flex flexDirection="column" gap="12px">
          <Text fontSize="28px" textAlign="center" lineHeight="30.8px">
            Save your players
            <br />
            and boosters
          </Text>
          <Text fontSize="14px" textAlign="center" lineHeight="16.8px">
            Ranks update when games are over
          </Text>
        </Flex>
        <Flex flexDirection="column" gap="16px" pos="relative">
          <Flex mb="-8px" fontSize="10px" fontWeight="500" lineHeight="1.2" opacity="0.6" justifyContent="flex-end">
            {watch('username')?.length ?? 0}/24
          </Flex>
          <FormControl isInvalid={!!errors.username}>
            <Input
              fontWeight="500"
              id="username"
              placeholder={user?.username}
              {...register('username', {
                required: 'Can’t be empty',
                minLength: { value: 3, message: "it's too short" },
                maxLength: { value: 24, message: 'it’s too long, should be 3-24 characters' },
              })}
              borderColor={isDirty ? '#fff' : '#FFFFFF99'}
              color={watch('username')?.length > 0 ? '#fff' : '#FFFFFF99'}
              boxShadow="none !important"
              _focus={{ opacity: '1', borderColor: '#fff', outline: 'none' }}
              _focusVisible={{
                opacity: '1',
                borderColor: (!isValid || !!errorMessage) && isDirty ? '#f93c3d' : '#fff',
                outline: 'none',
              }}
            />
          </FormControl>
          <FormControl isInvalid={!!errors.email}>
            <Input
              fontWeight="500"
              id="email"
              placeholder={user?.email ?? 'email (optional)'}
              {...register('email', {
                required: 'Can’t be empty',
                minLength: { value: 3, message: "It's too short" },
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Invalid email address',
                },
              })}
              borderColor={isDirty ? '#fff' : '#FFFFFF99'}
              color={watch('email')?.length > 0 ? '#fff' : '#FFFFFF99'}
              boxShadow="none !important"
              _focus={{ opacity: '1', borderColor: '#fff', outline: 'none' }}
              _focusVisible={{
                opacity: '1',
                borderColor: (!isValid || !!errorMessage) && isDirty ? '#f93c3d' : '#fff',
                outline: 'none',
              }}
            />
          </FormControl>
          <Button
            type="submit"
            isDisabled={!isValid || !!errorMessage || !!data}
            isLoading={isLoading}
            data-cy="submit-button"
          >
            save
          </Button>
          {errorMessage && (
            <SlideFade
              in={true}
              offsetY="-20px"
              style={{ position: 'absolute', top: 'calc(100% + 20px)', textAlign: 'center', width: '100%' }}
            >
              <Box w="100%" color="#f83c3c">
                {errorMessage}
              </Box>
            </SlideFade>
          )}
        </Flex>
      </Flex>
      <Box fontSize="14px" fontWeight="500" lineHeight="1.2" textAlign="center" opacity="0.6" mb="14px">
        username will be up on the
        <br />
        leaderboards and squad screen
      </Box>
    </Flex>
  );
};

export default ChangeUserInfoPage;
