import { Link } from 'react-router-dom';

import { Box, Grid, Text } from '@chakra-ui/react';
import { ContestParticipant } from 'models';
import routes from 'routes';

type SquadHeaderProps = {
  title: string;
  description: string;
  onClose?: () => void;
  participation: ContestParticipant;
  showTutorial?: () => void;
};

const SquadHeader = ({ title, description, onClose, participation, showTutorial }: SquadHeaderProps) => {
  const isFakeUser = localStorage.getItem('is_fake_user') === 'true';

  const closeButton = (
    <>
      {onClose && (
        <Box fontSize="14px" fontWeight="500" onClick={onClose} cursor="pointer">
          close
        </Box>
      )}
      {participation.status === 'completed' && !onClose && (
        <Box fontSize="14px" fontWeight="500" as={Link} to={routes.home}>
          close
        </Box>
      )}
    </>
  );

  return (
    <Grid gridTemplateColumns="48px 1fr 48px" gap="8px" py="8px" px="12px">
      <Box display="flex" alignItems="center" justifyContent="center">
        {participation.status !== 'completed' && (
          <Box
            onClick={showTutorial}
            height="36px"
            width="36px"
            borderRadius="50%"
            backgroundColor="#111111"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="20px"
          >
            ?
          </Box>
        )}
      </Box>
      <Box textAlign="center">
        <Text fontSize="18px" fontWeight="800" lineHeight="21.6px">
          {title}
        </Text>
        <Text variant="text1" mt="4px">
          {description}
        </Text>
      </Box>
      <Box>{closeButton}</Box>
    </Grid>
  );
};

export default SquadHeader;
