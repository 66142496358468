import { CSSProperties, FC, MutableRefObject, forwardRef, useEffect, useRef, useState } from 'react';

import { Box, BoxProps, AspectRatio as ChakraAspectRatio, Flex, Image as Img, Text } from '@chakra-ui/react';
import { Card } from 'models';

import { Back } from './Back';
import { Face } from './Face';

const defaultAspectRatio = 220 / 330;

type Props = BoxProps & {
  card: Card;
  width?: string;
  detailed?: boolean;
  styles?: {
    image?: CSSProperties;
    info?: CSSProperties;
    firstName?: CSSProperties;
    lastName?: CSSProperties;
  };
};

const PlayerCard = forwardRef<HTMLDivElement, Props>(({ card, width, styles, detailed, ...props }, ref) => {
  const [calcWidth, setCalcWidth] = useState(width ?? 'auto');
  const [aspectRatio, setAspectRatio] = useState<number>(width ? parseInt(width) / 220 : defaultAspectRatio);
  const [isOriginalImageUploaded, setIsOriginalImageUploaded] = useState(false);
  const container = useRef<HTMLDivElement>();
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    if (width) return;
    if (container.current && card) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const newHeight = entry.contentRect.height;
          const newWidth = aspectRatio * newHeight;
          setAspectRatio(newWidth / 200);
          setCalcWidth(`${newWidth}px`);
        }
      });

      resizeObserver.observe(container.current);
    }
  }, [width]);

  useEffect(() => {
    setIsOriginalImageUploaded(false);
    if (!card) return;
    const img = new Image();
    img.src = card?.headshotThumbnails?.original ?? '';
    img.onload = () => {
      setIsOriginalImageUploaded(true);
    };
  }, [card]);

  const combinedRef = (element: HTMLDivElement) => {
    if (ref && typeof ref === 'object' && 'current' in ref) {
      (ref as MutableRefObject<HTMLDivElement | null>).current = element;
    }
    container.current = element;
  };

  const headshotSrc = isOriginalImageUploaded ? card?.headshotThumbnails?.original : card?.headshotThumbnails?.small;

  return (
    <Box
      pos="relative"
      p={`${8 * aspectRatio}px`}
      bg="white"
      w={calcWidth}
      borderRadius={`${16 * aspectRatio}px`}
      ref={combinedRef}
      boxShadow={`0px ${4 * aspectRatio}px ${32 * aspectRatio}px 0px #00000080`}
      {...props}
    >
      <Face
        detailed={detailed}
        calcWidth={calcWidth}
        styles={styles}
        isOriginalImageUploaded={isOriginalImageUploaded}
        aspectRatio={aspectRatio}
        card={card}
        hasInjuryStatus={card.playerInjuries}
      />

      {/* <Back
          detailed={detailed}
          calcWidth={calcWidth}
          styles={styles}
          aspectRatio={aspectRatio}
          isOriginalImageUploaded={isOriginalImageUploaded}
          player={player}
          pos="absolute"
          /> */}
    </Box>
  );
});

export default PlayerCard;
