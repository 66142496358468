import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { use } from 'chai';

import EditAvatar from './EditAvatar';
import EditBio from './EditBio';
import EditUserName from './EditUserName';

type EditionType = 'name' | 'bio' | 'avatar';

const ProfileEditorPage = () => {
  const { type } = useParams<{ type: EditionType }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!type || !['name', 'bio'].includes(type)) {
      navigate('/');
    }
  }, [type]);

  if (type === 'bio') {
    return <EditBio />;
  } else if (type === 'avatar') {
    return <EditAvatar />;
  } else if (type === 'name') {
    return <EditUserName />;
  }

  return null;
};

export default ProfileEditorPage;
