import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Fade, Flex } from '@chakra-ui/react';
import { LeaderboardStatPeriod } from 'models';
import checkMeIsEmpty from 'utils/checkMeIsEmpty';
import { getOrdinalSuffix } from 'utils/getOriginalSuffix';

import LeaderboardInviteRow from './LeaderboardInviteRow';
import LeaderboardPeriodRow from './LeaderboardPeriodRow';
import NoParticipants from './NoParticipants';

type Props = {
  stat: LeaderboardStatPeriod;
  showLeaderboard?: boolean;
  showMySquad?: boolean;
};

const LeaderboardPeriod: FC<Props> = ({ stat, showLeaderboard = true, showMySquad = true }) => {
  const [meIsEmpty, setMeIsEmpty] = useState<boolean>(true);
  const [searchParams] = useSearchParams();

  const allUsers = stat.users;
  const friends =
    stat.me && allUsers.length <= 19 ? [...allUsers, stat.me].sort((a, b) => a.place - b.place) : allUsers;
  const users = searchParams.get('friends') === 'true' ? friends : allUsers;

  const [isOpen, setIsOpen] = useState<boolean[]>([]);

  useEffect(() => {
    if (stat.me) {
      setMeIsEmpty(checkMeIsEmpty(stat.me));
    }
  }, [stat.me]);

  // useEffect(() => {
  //   if (users) {
  //     const openBoosters = async () => {
  //       for (let i = 0; i < users.length; i++) {
  //         await new Promise((resolve) => setTimeout(resolve, 50));
  //         setIsOpen((prev) => {
  //           const newIsOpen = [...prev];
  //           newIsOpen[i] = true;
  //           return newIsOpen;
  //         });
  //       }
  //     };
  //     openBoosters();
  //   }
  // }, [users, stat.users]);

  const myPlaceAmongFriends = users.findIndex((u) => u.user.id === stat.me?.user.id) + 1;
  const totalPoints = stat.me?.points === null || stat.me?.points === undefined ? 'TBA' : stat.me?.points;
  const place = searchParams.get('friends') === 'true' ? myPlaceAmongFriends : stat.me?.place;
  const placeString = place ? place.toString() : '0';
  const ordinalSuffix = getOrdinalSuffix(parseInt(placeString));
  const pointsText = `you're ${place}${ordinalSuffix} with ${totalPoints}`;
  const text = (() => {
    if (searchParams.get('friends') === 'true') {
      if (users.findIndex((u) => u.user.id === stat.me?.user.id) > -1) {
        return pointsText;
      } else return 'my squad';
    } else if ((searchParams.get('friends') === 'false' && stat.me?.points !== null) || stat.me?.points !== undefined) {
      return pointsText;
    }

    return 'my squad';
  })();

  return (
    <>
      {(showLeaderboard || showMySquad) && (
        <Flex flexDirection="column" flex="1">
          {!!stat.me && showMySquad && (
            <Box
              w="min-content"
              mx="auto"
              bg="#F83C3C"
              p="5px 12px"
              borderRadius="20px"
              mt="16px"
              mb="8px"
              boxShadow="0 0 50px 0 rgba(248, 60, 60, 0.25)"
            >
              <Flex align="center" justify="space-between" gap="8px">
                <Flex align="center" gap="8px" maxW="218px" marginRight="auto">
                  <Flex
                    w="28px"
                    h="28px"
                    borderRadius="8px"
                    background="#FFFFFF1A"
                    fontSize="16px"
                    fontWeight="800"
                    justify="center"
                    align="center"
                    color="#FFFFFFCC"
                    flexShrink="0"
                    textTransform="uppercase"
                    backgroundPosition="center center"
                    backgroundSize="cover"
                    backgroundImage={stat.me ? `url(${stat.me.user.avatarUrl})` : undefined}
                  >
                    {stat.me.user.avatarUrl ? null : stat.me.user.username[0]}
                  </Flex>
                </Flex>
                <Flex fontSize="14px" fontWeight="700" lineHeight="16.8px" align="center" gap="7px" whiteSpace="nowrap">
                  {text}
                </Flex>
              </Flex>
            </Box>
          )}
          {showLeaderboard && (
            <>
              {showLeaderboard && users.length > 0 && (
                <Flex flexDirection="column">
                  {users.map((u, index) => (
                    <Fade key={`${u.user.id}_${index}`} in={true} unmountOnExit>
                      <LeaderboardPeriodRow position={index} participant={u} key={u.user.id} />
                    </Fade>
                  ))}
                </Flex>
              )}
              {showLeaderboard && searchParams.get('friends') === 'true' && (
                <Flex flexDirection="column">
                  {Array.from({ length: 2 }).map((_, index) => (
                    <LeaderboardInviteRow key={`invite-row-${index}`} />
                  ))}
                </Flex>
              )}
              {showLeaderboard && stat.currentLeaderboard !== undefined && users.length <= 0 && (
                <Flex h="100%" flexDirection="column">
                  <NoParticipants nextContest={null} />
                </Flex>
              )}
            </>
          )}
        </Flex>
      )}
    </>
  );
};

export default LeaderboardPeriod;
