import { FC } from 'react';

import { Box, Fade, Flex, Grid } from '@chakra-ui/react';
import { Contest, LeaderboardStat } from 'models';

import { ReactComponent as TrophyIcon } from 'icons/trophy.svg';

import NextContestCountAsDescription from './NextContestCountAsDescription';

const NotStartedMessage: FC<{ stat: LeaderboardStat }> = ({ stat }) => {
  return (
    <Grid pos="relative" pt="8px" pb="20px" gridTemplateRows="1fr auto" flex="3">
      <Flex flexDir="column" align="center" justify="center" gap="16px" px="12px">
        <Fade in={true}>
          <Box as={TrophyIcon} mx="auto" mb="8px" />
          <Box fontSize="14px" fontWeight="600" lineHeight="16.8px" opacity="0.8" textAlign="center">
            Squad ranks drop once
            <br />
            games are done. Stay tuned!
          </Box>
        </Fade>
        {/* {nextContest && <NextGame data={nextContest} />} */}
        {stat.nextContest && stat.nextContest.status === 'scheduled' && (
          <NextContestCountAsDescription data={stat.nextContest} />
        )}
      </Flex>
    </Grid>
  );
};

export default NotStartedMessage;
