import { FC, useMemo } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { LeaderboardStat } from 'models';
import { formatNumberWithCommas } from 'utils/formatNumberWithCommas';
import { getOrdinalSuffix } from 'utils/getOriginalSuffix';

import { ReactComponent as LeftArrowIcon } from 'icons/left-arr.svg';
import { ReactComponent as RightArrowIcon } from 'icons/right-arr.svg';

import LeaderboardHeaderSkeleton from './LeaderboardHeaderSkeleton';

type Props = {
  stat?: LeaderboardStat;
  onNext(id: number): void;
  onPrev(id: number): void;
  disabled: boolean;
};

const LeaderboardHeader: FC<Props> = ({ disabled, stat, onNext, onPrev }) => {
  const dateStr = useMemo(() => {
    if (!stat) return '';
    const startsAtNewYork = dayjs(stat.currentContest.startsAt).tz('America/Los_Angeles');
    const day = startsAtNewYork.format('D');
    const postfix = getOrdinalSuffix(parseInt(day));
    return startsAtNewYork.format('MMM ') + day + postfix;
  }, [stat]);

  if (!dateStr) return <LeaderboardHeaderSkeleton />;

  return (
    <Flex
      bottom="0"
      justify="center"
      flexDir="column"
      align="center"
      fontSize="14px"
      lineHeight="16.8px"
      pos="sticky"
      padding="11px 18px 11px"
      bg="#242424"
      zIndex="10"
      borderTop="1px solid rgba(255,255,255,0.4)"
      mx="-12px"
    >
      <Box fontWeight="800">{dateStr}</Box>
      <Box fontWeight="600" fontSize="14px" lineHeight={1.2} opacity={0.4}>
        {formatNumberWithCommas(stat?.currentContest.participantsCount ?? 0)} squads
      </Box>
      <Box
        pos="absolute"
        top="50%"
        left="12px"
        transform="translateY(-50%)"
        opacity={!disabled && stat?.previousContest ? '1' : '0.3'}
        onClick={!disabled && stat?.previousContest ? () => onPrev(stat.previousContest!.id) : undefined}
        w="33.6px"
        h="33.6px"
        borderRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        transition="all 0.2s ease-in-out"
        // backgroundColor="rgba(255,255,255,0.05)"
        _active={{ backgroundColor: 'rgba(255,255,255,0.1)' }}
      >
        <Box as={LeftArrowIcon} />
      </Box>
      <Box
        pos="absolute"
        top="50%"
        right="12px"
        transform="translateY(-50%)"
        opacity={!disabled && stat?.nextContest ? '1' : '0.3'}
        onClick={!disabled && stat?.nextContest ? () => onNext(stat.nextContest!.id) : undefined}
        w="33.6px"
        h="33.6px"
        borderRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        transition="all 0.2s ease-in-out"
        // backgroundColor="rgba(255,255,255,0.05)"
        _active={{ backgroundColor: 'rgba(255,255,255,0.1)' }}
      >
        <Box as={RightArrowIcon} />
      </Box>
    </Flex>
  );
};

export default LeaderboardHeader;
