import { FC } from 'react';

import { Box, BoxProps, keyframes } from '@chakra-ui/react';
import { Card } from 'models';

type CardPlaceholderProps = {
  card?: Card;
  showAnimation?: boolean;
} & BoxProps;

const onLoadAnimation = keyframes`
  0% {
    scale: 1;
    opacity: 1;
  }
  99% {
    scale: 1.5;
    opacity: 0.3;
  }
  100% {
    display: none;
  }
`;

const CardPlaceholder: FC<CardPlaceholderProps> = ({ card, showAnimation, ...props }) => {
  const headshot =
    card?.headshotThumbnails?.small ?? card?.headshotThumbnails?.medium ?? card?.headshotThumbnails?.original ?? '';

  return (
    <Box
      w="64px"
      h="64px"
      backgroundColor={card ? 'white' : '#343434'}
      borderRadius="14px"
      backgroundImage={card ? `url(${headshot})` : ''}
      backgroundSize="cover"
      backgroundPosition="center center"
      position="relative"
      {...props}
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        opacity="0"
        backgroundColor="rgba(255, 255, 255, 0.5)"
        borderRadius="14px"
        animation={headshot && showAnimation ? `${onLoadAnimation} 0.3s ease-in-out forwards` : ''}
      />
      {card?.playerInjuries && (
        <Box
          position="absolute"
          top="-7px"
          right="-5px"
          w="20px"
          h="20px"
          color="rgba(36, 36, 36, 1)"
          fontSize="8px"
          lineHeight="1"
          fontWeight="bold"
          fontFamily="JetBrains Mono"
          display="flex"
          textTransform="uppercase"
          alignItems="center"
          justifyContent="center"
          borderRadius="50%"
          backgroundColor="rgba(255, 165, 0, 1)"
        >
          gtd
        </Box>
      )}
    </Box>
  );
};

export default CardPlaceholder;
